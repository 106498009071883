import React from "react"
import SEO from "../../components/seo"
import HeroFeaturePage from "../../components/pages/shared/hero/feature-page"
import Layout from "../../components/layout"
import AboutComponent from "../../components/pages/main/about"
import ContactFormComponent from "../../components/pages/shared/contact-form"
import FeaturesComponent from "../../components/pages/main/landing-features"
import FeaturesGridComponent from "../../components/pages/features/grid"
import Discount from "../../components/pages/main/discount"
import Banner from "../../components/pages/main/banner"
import Breadcrumb from '../../components/controls/breadcrumbs'

const ClientsFeaturePage = (props) => {
  const crumbs = [
    { name: 'Главная', url: '/'},
    { name: 'Возможности', url: '/feature'},
    { name: 'Учет абонементов', url: ''}
  ];
  return (
    <Layout headerColor={"#03C4A4"} page={"subscriptions"}>
      <SEO
        title="Абонементы | CRM-система учета для детских центров"
        keywords={[`учет абонементов`, `продажи абонементов`, `список абонементов`]}
        description="Облачная CRM Параплан автоматизирует создание и продажу абонементов любого типа в детских и учебных центрах, франшизах, танцевальных студиях, языковых и спортивных школах."
        url={"https://paraplancrm.ru" + props.location.pathname}
      />
      <HeroFeaturePage
        name={"subscriptions"}
        title='Создавайте абонементы'
        description='Наши инструменты позволят быстро провести продажу и&nbsp;переключить внимание администратора на клиента.'
        color='#03C4A4'
        btnDark={true}
        image={require("../../images/cover/subscriptions.png")} />
      <Breadcrumb crumbs={ crumbs } arrowcolor={"green"}/>
      <AboutComponent
        title='Наглядность <br/> в деле'
        description='Интуитивно понятный интерфейс в программе учета посещений, записей и абонементов позволяет сотрудникам быстрее получать доступ к актуальным абонементам и быстро проводить сделки.'
        image={require("../../images/screenshot/subscription/screenshot_beauty.png")}
        imageWidth={673}
        imageHeight={434}
        bgSize={"position-bottom"}
        reverse={true}
        color='#E65D49' />
      <Discount />
      <AboutComponent
        title='Прозрачный учет'
        description='Вы заранее задаете тарифные сетки, а система ведет бухгалтерию и отображает результаты продаж.'
        image={require("../../images/screenshot/subscription/screenshot_beauty-2.png")}
        imageWidth={673}
        imageHeight={246}
        bgSize={"center-oval"}
        color='#319DED' />
      <AboutComponent
        title='Гибкость <br/> настройки'
        description='Вы можете задать любой тип абонемента, будто то на количество посещений или на период действия. Вопрос как вести учет абонементов салона красоты или студии, массажного салона или частного мастера решает Параплан CRM.'
        image={require("../../images/screenshot/subscription/screenshot_beauty-3.png")}
        bgSize={'clear'}
        imageWidth={672}
        imageHeight={428}
        reverse={true}
        color='#FFC800' />
      <Banner withMargins={true} />
      <FeaturesGridComponent
        title={"Охватите все возможности"}
        subtitle={"Система Параплан обеспечит вас инструментами для быстрого роста бизнеса."}
        features={[
          {
            name: "Списание посещений",
            text: "Мастер отмечает клиента, а с абонемента автоматически списывается посещение.",
            image: require("../../images/icons/subscription_attendance.svg"),
          },
          {
            name: "Истекающие абонементы",
            text: "Главный экран покажет вам истекающие абонементы и напомнит оформить новый.",
            image: require("../../images/icons/subscription_expiring.svg"),
          },
          {
            name: "Пропущенные визиты",
            text: "Система позволяет сделать возврат средств при необходимости за пропущенные посещения или использовать их для оплаты следующего абонемента.",
            image: require("../../images/icons/subscription_refund.svg"),
          },
          {
            name: "Оценка эффективности",
            text: "Система рассчитает, какой абонемент продается лучше всего, а вы сможете направить внимание отдела продаж на выгодные сделки.",
            image: require("../../images/icons/subscription_analytics.svg"),
          },
        ]}
      />
      <FeaturesComponent title="Дополнительные возможности" />
      <ContactFormComponent />
    </Layout>
  )
}

export default ClientsFeaturePage
